import CreateContainer from "views/create/Create.component";
import SummaryContainer from "views/summary/Summary.container";
import ReviewContainer from "views/review/Review.container";
import HomeContainer from "views/home/Home.container";
import Main from "blocks/Main.container";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { memo } from "react";
// import RootComponent from "old/entry/Root.component";

import routes from "./routes.constants";

const Router = () => (
  <BrowserRouter>
    <Main>
      <Routes>
        <Route index path='/' Component={HomeContainer} />
        {/* <Route index path='/old' Component={RootComponent} /> */}
        <Route path={routes.Create()} Component={CreateContainer} />
        <Route path={routes.Review()} Component={ReviewContainer} />
        <Route path={routes.Summary()} Component={SummaryContainer} />
      </Routes>
    </Main>
  </BrowserRouter>
);

export default memo(Router);
