import { Button, Dropdown, Flex, MenuProps } from "antd";
import {
  DeleteOutlined,
  RightOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { memo, useCallback, useEffect } from "react";
import CsvDownloaderComponent from "components/CsvDownloader/CsvDownloader.component";
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import Field from "components/Field/Field.component";
import { Link } from "react-router-dom";
import routes from "routes/routes.constants";
import { FormType, KeysFieldType } from "types/form.types";
import { v4 } from "uuid";
import { useI18n } from "hooks/useI18n.hook";
import { useCopyToClipboard } from "usehooks-ts";
import FieldSelect from "components/Field/FieldSelect.component";

import "./index.scss";

import ControlsComponent from "./Controls.component";
import { LANGUAGES } from "./constants";

const CONTROLLERS = [
  { value: "Mobile", label: "Mobile" },
  { value: "ClientAreaApi", label: "ClientAreaApi" },
  { value: "payment-gateway-resources", label: "Payment Gateway Resources" },
  { value: "Page", label: "Page" },
];

const CreateContainer = () => {
  const translate = useI18n();

  const {
    control,
    getValues,
    setValue,
    formState: { isValid, isValidating },
  } = useFormContext<FormType>();

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "keys",
  });

  const [copiedText, copy] = useCopyToClipboard();

  const formValues = getValues("keys");

  useEffect(() => {
    if (!getValues("keys").length)
      append({
        Key: "",
        Translate: "",
        Controller: undefined,
        GUID: 0,
        View: "Labels",
        Language: "English",
        ResourceType: "3",
        CountryCodes: "",
      });
  }, [append, formValues.length, getValues]);

  const onUploadCsvFile = useCallback(
    (json: KeysFieldType[]) => {
      const _keys = formValues.filter((key) => !!key.Key || !!key.Translate);
      setValue("keys", [..._keys, ...json]);
    },
    [formValues, setValue]
  );

  const onAddRow = useCallback(
    (row?: Partial<KeysFieldType>) => {
      append({
        GUID: v4(),
        Key: "",
        Translate: "",
        Controller: formValues?.[formValues.length - 1]?.Controller,
        View: "Labels",
        Language: "English",
        ResourceType: "3",
        CountryCodes: "",
        ...row,
      });
    },
    [append, formValues]
  );

  const onACopyRow = useCallback(
    (index: number, Language: KeysFieldType["Language"]) => {
      const { Controller, Key, View } = getValues(`keys.${index}`);
      insert(index + 1, {
        GUID: v4(),
        Key,
        Translate: "",
        Controller,
        View,
        Language,
        ResourceType: "3",
        CountryCodes: "",
      });
    },
    [getValues, insert]
  );

  const items: (
    field: FieldArrayWithId<FormType, "keys", "id">,
    index: number
  ) => MenuProps["items"] = useCallback(
    // (field: FieldArrayWithId<FormType, "keys", "id">, index: number) => [
    (field: FieldArrayWithId<FormType, "keys", "id">, index: number) =>
      LANGUAGES.map((lang) => ({
        label: lang.displayName,
        key: lang.name,
        onClick: () => onACopyRow(index, lang.name),
      })),
    [onACopyRow]
  );

  const renderRow = useCallback(
    (field: FieldArrayWithId<FormType, "keys", "id">, index: number) => (
      <Flex key={field.id} gap={6} align='center' justify='center'>
        <FieldSelect
          // control={control}
          name={`keys.${index}.Controller`}
          options={CONTROLLERS}
          placeholder={translate("CONTROLLER")}
        />
        {/* <input {...register(`keys.${index}.Key`)} /> */}
        <Field
          control={control}
          name={`keys.${index}.Key`}
          placeholder={translate("KEY")}
          style={{ minWidth: 300 }}
        />
        <Field
          control={control}
          name={`keys.${index}.Translate`}
          placeholder={translate("TRANSLATION")}
          style={{ minWidth: 500 }}
          tag={field.Language}
          // tag={getLanguageTag(field.Language)}
        />
        <Dropdown menu={{ items: items(field, index) }} placement='bottomLeft'>
          <Button
            type='text'
            size='small'
            // icon={<PlusSquareTwoTone />}
            // icon={<PlusCircleTwoTone />}
            icon={<PlusSquareOutlined />}
            style={{ flexShrink: 0 }}
          />
        </Dropdown>
        <Button
          disabled={fields.length === 1}
          onClick={() => remove(index)}
          // icon={<DeleteTwoTone />}
          icon={<DeleteOutlined />}
          type='text'
          size='small'
          style={{ flexShrink: 0 }}
        />
      </Flex>
    ),
    [control, fields.length, items, remove, translate]
  );

  return (
    <Flex gap={12} vertical align='center' style={{ height: "100%" }}>
      <div className='list'>
        <p>{copiedText}</p>
        <Flex gap={12} vertical align='center'>
          {fields.map(renderRow)}
          <ControlsComponent onAdd={onAddRow} onUpload={onUploadCsvFile} />
          {/* <Flex gap={6}>
            <Button onClick={onAddRow} icon={<PlusOutlined />} size='small'>
              {translate("ADD_ROW")}
            </Button>
            <CsvUploader
              text={translate("LOAD_CSV")}
              onChange={onUploadCsvFile}
            />
          </Flex> */}
        </Flex>
      </div>
      <Flex gap={6} align='end' justify='center' style={{ paddingBottom: 16 }}>
        <CsvDownloaderComponent disabled={!isValid || isValidating} />
        <Link to={routes.Review()}>
          <Button
            disabled={!isValid || isValidating}
            size='large'
            style={{ width: 200 }}
            type='primary'
            htmlType='submit'
            iconPosition='end'
            icon={<RightOutlined />}
          >
            {translate("PROCEED")}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default memo(CreateContainer);
