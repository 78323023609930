import { Input, InputProps, SelectProps, Tag } from "antd";
import classNames from "classnames";
import {
  Control,
  Controller,
  Path,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { FormType } from "types/form.types";
import "./index.scss";
import { useRef } from "react";
import { useResizeObserver } from "usehooks-ts";

type FieldType<T extends FormType> = {
  control?: Control<T>;
  name: Path<T>;
  type?: "text" | "password" | "email" | "textarea";
  options?: never;
  rules?: UseControllerProps["rules"];
  tag?: string;
} & InputProps;

type FieldSelectType<T extends FormType> = {
  control?: Control<T>;
  name: Path<T>;
  // type: "select";
  options: SelectProps["options"];
  rules?: UseControllerProps["rules"];
  tag?: string;
} & InputProps;

const Field = <T extends FormType>({
  // control,
  name,
  type = "text",
  options = [],
  rules = {},
  tag,
  ...rest
}: FieldType<T> | FieldSelectType<T>) => {
  const { register, getFieldState, control } = useFormContext<FormType>();

  const tagRef = useRef(null);

  const { width = 0, height = 0 } = useResizeObserver({
    ref: tagRef,
    box: "border-box",
  });

  const { invalid } = getFieldState(name);
  const className = classNames({
    // field: true,
    // "field--invalid": invalid,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true, ...rules }}
      render={({ field }) => (
        <div className='field'>
          <Input
            {...field}
            className={className}
            type={type}
            {...rest}
            style={{ minWidth: 150, paddingRight: width + 20, ...rest.style }}
            value={String(field.value)}
          />
          {tag && (
            <Tag ref={tagRef} className='field-tag' color='processing'>
              {tag}
            </Tag>
          )}
        </div>
      )}
    />
    // <input
    //   placeholder={placeholder}
    //   {...register(name, { required: true, ...rules })}
    //   className={className}
    // />
  );
};

export default Field;
