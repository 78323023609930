import { Card, Flex } from "antd";
import { ArgsProps } from "antd/es/notification";
import { memo, useMemo } from "react";

export const composeNotificationError = ({
  code = 0,
  message,
}: {
  code?: number;
  message?: string;
}): ArgsProps => {
  // const codeNode = code ? `${code}: ` : '';
  // const messageNode = message || '';

  // const error = codeNode + messageNode;

  return {
    message: code ?? message ?? undefined,
    description: code ? message : undefined,
    duration: 0,
  }
}

const ErrorMessage = ({
  code = 0,
  message,
}: {
  code?: number;
  message?: string;
}) => {
  return (
    <Flex gap={4} vertical>
      {code > 200 && (
        <Card className='server-error'>
          <p className='server-error-title'>Oops!</p>
          {code && <p className='server-error-message'>Error code: {code}</p>}
          {message && (
            <p className='server-error-message'>Message: {message}</p>
          )}
        </Card>
      )}
    </Flex>
  );
};

export default ErrorMessage;
