import {
  Button,
  Card,
  Descriptions,
  Empty,
  Flex,
  notification,
  Tag,
} from "antd";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { memo, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  HomeOutlined,
  FileProtectOutlined,
  StarOutlined,
} from "@ant-design/icons";
import Confetti from "react-confetti";
import { FormAuth, FormAuthEnv, FormType } from "types/form.types";
import { jsonToCsv } from "utils/jsonToCsv";
import api, { ImportResponseFail } from "api";
import { Link, useNavigate } from "react-router-dom";
import routes from "routes/routes.constants";
import { composeNotificationError } from "components/ErrorMessage/ErrorMessage.component";
import AuthForm from "components/AuthForm/AuthForm.component";
import { useI18n } from "hooks/useI18n.hook";
import classNames from "classnames";

const SummaryContainer = () => {
  const { getValues } = useFormContext<FormType>();
  const [notificationApi, contextHolder] = notification.useNotification({
    top: 16,
    placement: "bottom",
  });

  const navigate = useNavigate();
  const translate = useI18n();

  const formValues = getValues();
  const fromCount = formValues.keys.length;

  type StatusState = {
    loading: boolean;
    code: number;
    imported: number;
    ignored: number;
    message: string;
  };

  const [runConfetti, updateRunConfetti] = useState(false);

  const [status, updateStatus] = useState<Record<FormAuthEnv, StatusState>>({
    development: {
      loading: false,
      code: 0,
      imported: 0,
      ignored: 0,
      message: "",
    },
    qa: {
      loading: false,
      code: 0,
      imported: 0,
      ignored: 0,
      message: "",
    },
    production: {
      loading: false,
      code: 0,
      imported: 0,
      ignored: 0,
      message: "",
    },
  });

  const data = jsonToCsv(formValues.keys);

  const onSubmit = useCallback(
    (i: FormAuth) => {
      updateStatus({
        ...status,
        [i.env]: { loading: true, code: 0, message: "" },
      });
      // toggleProcessingImport(true);
      api
        .postImport(i, data)
        .then((response) => {
          // setImportedCount(response.importedCount);
          // setIgnoredCount(response.ignoredCount);
          // toggleProcessingImport(false);
          // toggleProcessingImportCompleted(true);
          updateRunConfetti(true);
          updateStatus({
            ...status,
            [i.env]: {
              loading: false,
              code: 200,
              imported: response.importedCount,
              ignored: response.ignoredCount,
            },
          });
          notificationApi.success({
            message: translate("STATUS_DONE"),
            duration: 3,
            // description: (
            //   <Flex vertical gap={4}>
            //     <p>Brand: {i.brand?.name}</p>
            //     <p>Environment: {i.env}</p>
            //     <p>Imported: {response.importedCount}</p>
            //     <p>Ignored: {response.ignoredCount}</p>
            //   </Flex>
            // )
            // description: `
            // brand: ${i.brand?.name},
            // environment: ${i.env}
            // imported: ${response.importedCount}
            // ignored: ${response.ignoredCount}
            // `
            // description: `${response.importedCount} key(s) successfully imported to ${i.env}`
          });
        })
        .catch((error: ImportResponseFail) => {
          updateStatus({
            ...status,
            [i.env]: {
              loading: false,
              code: error.response?.status ?? 0,
              message: error.response?.data.message ?? "",
              // imported: response.importedCount,
              // ignored: response.ignoredCount,
            },
          });
          notificationApi.error({
            ...composeNotificationError({
              code: error.response?.status,
              message: error.response?.data.message,
            }),
            // description: <ErrorMessageComponent code={error.response?.status} message={error.response?.data.message} />,
            duration: 3,
            // icon: <ExclamationCircleTwoTone twoToneColor='red' />
          });
        });
    },
    [data, notificationApi, status, translate]
  );

  const [parent] = useAutoAnimate();

  // if (status.code === 200) {
  //   return (
  //     <Result
  //       status="success"
  //       title={`Successfully imported ${status.imported} key${status.imported > 1 ? 's' : ''}!`}
  //       // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
  //       extra={[
  //         <Link to={routes.Root()}>
  //           <Button type="primary" icon={<HomeOutlined />} iconPosition="end">Go home</Button>,
  //         </Link>
  //         // <Button type="primary" key="console">
  //         //   Go Console
  //         // </Button>,
  //       ]}
  //     />
  //   )
  // }

  useEffect(() => {
    if (!formValues.keys.length) navigate(routes.Root());
  }, [formValues.keys.length, navigate]);

  const title = useCallback(
    (i: FormAuth) => {
      if (status[i.env].loading) return "Processing...";

      if (status[i.env].code === 200) return "Successfully imported!";

      // if (status.code > 200) return "Failed to import";

      return translate("READY_FOR_IMPORT");
    },
    [status, translate]
  );

  const renderButton = useCallback(
    (i: FormAuth) => {
      if (status[i.env].code === 200)
        return (
          <Link to={routes.Root()}>
            <Button
              className='button--success'
              type='primary'
              size='middle'
              color='success'
              block
              // icon={<HomeOutlined />}
              // iconPosition='end'
            >
              Go home
            </Button>
          </Link>
        );

      // if (!status.code)
      return (
        <Button
          block
          // loading={status.loading}
          disabled={status[i.env].loading || !formValues.auth[i.env].token}
          // disabled={!formValues.keys.length}
          type='primary'
          size='middle'
          onClick={() => onSubmit(i)}
          className={classNames({
            "button--animated":
              !status[i.env].loading && formValues.auth[i.env].token,
          })}
        >
          {translate("COMPLETE_IMPORT")}
          {/* {status.loading ? "Loading" : "Complete import"} */}
        </Button>
      );
    },
    [formValues.auth, onSubmit, status, translate]
  );

  const renderAuthLoader = useCallback(
    () => (
      <Card bordered={false} style={{ width: 400, height: 300 }}>
        <Empty
          style={{ background: "var(--white)", height: "inherit" }}
          description='Log in to continue'
          image={
            <FileProtectOutlined
              style={{ fontSize: 50, color: "var(--background-root)" }}
            />
          }
          imageStyle={{ height: 50 }}
        />
      </Card>
    ),
    []
  );

  const renderFinalCard = useCallback(
    (i: FormAuth) => (
      <Flex key={i.env} vertical gap={8} style={{ height: "100%" }}>
        <Card
          // loading={!formValues.auth[i.env].token}
          // loading={status.loading}
          bordered={false}
          style={{ height: "100%" }}
          title={title(i)}
          extra={renderButton(i)}
          tabBarExtraContent={<div>Extra</div>}
        >
          <Flex vertical gap={18}>
            <Descriptions
              colon
              layout='horizontal'
              column={1}
              size='small'
              contentStyle={{ justifyContent: "end" }}
              items={[
                {
                  key: "2",
                  label: translate("ENVIRONMENT"),
                  children: (
                    <Tag bordered={false} color='processing'>
                      {i.env}
                    </Tag>
                  ),
                },
                {
                  key: "1",
                  label: translate("BRAND"),
                  children: (
                    <Tag bordered={false} color='processing'>
                      {i.brand?.name || "-"}
                    </Tag>
                  ),
                },
                {
                  key: "3",
                  label: translate("TOTAL"),
                  children: (
                    <Tag bordered={false} color='processing'>
                      {fromCount || "-"}
                    </Tag>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <span className='color-success'>
                      {translate("IMPORTED")}
                    </span>
                  ),
                  children: (
                    <Tag bordered={false} color='success'>
                      {status[i.env].code === 200
                        ? status[i.env].imported
                        : "-"}
                    </Tag>
                  ),
                },
                {
                  key: "5",
                  label: (
                    <span className='color-error'>{translate("IGNORED")}</span>
                  ),
                  children: (
                    <Tag bordered={false} color='error'>
                      {status[i.env].code === 200
                        ? status[i.env].ignored ?? 0
                        : "-"}
                    </Tag>
                  ),
                },
              ]}
            />
          </Flex>
        </Card>
      </Flex>
    ),
    [fromCount, renderButton, status, title, translate]
  );

  const renderCard = useCallback(
    (i: FormAuth) => (
      <Flex key={i.env} vertical gap={8} ref={parent} style={{ maxWidth: 400 }}>
        <Tag
          color='processing'
          icon={i.env === "production" && <StarOutlined />}
          style={{ width: "fit-content" }}
        >
          {i.env}
        </Tag>

        <Card>
          <AuthForm path={i.env} />
        </Card>
        {renderFinalCard(i)}
      </Flex>
    ),
    [parent, renderFinalCard]
  );

  return (
    <Flex gap={16} align='start' justify='center' wrap>
      {contextHolder}
      {Object.values(formValues.auth).map(renderCard)}
      {/* {runConfetti && (
        <Confetti
          recycle={false}
          onConfettiComplete={() => updateRunConfetti(false)}
        />
      )} */}
    </Flex>
  );
};

export default memo(SummaryContainer);
