export const LANGUAGES = [
  {
    id: 1,
    name: "English",
    displayName: "English",
    code: "en",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-01-06T02:42:21.5762641Z",
    updatedOn: "2023-01-06T02:42:21.5762641Z",
  },
  {
    id: 2,
    name: "Spanish",
    displayName: "Espanol",
    code: "es",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-01-17T11:19:34.2256171Z",
    updatedOn: "2023-01-17T11:19:34.2256171Z",
  },
  {
    id: 3,
    name: "Portuguese",
    displayName: "Portuguese",
    code: "pt",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-01-17T11:20:34.2363013Z",
    updatedOn: "2023-01-17T11:20:34.2363013Z",
  },
  {
    id: 11,
    name: "Chinese (Traditional)",
    displayName: "Chinese (Traditional)",
    code: "zh",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-09-28T08:23:54.9752590Z",
    updatedOn: "2023-09-28T08:23:54.9752590Z",
  },
  {
    id: 12,
    name: "Turkish",
    displayName: "Turkish",
    code: "tr",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-04T15:33:33.8657152Z",
    updatedOn: "2023-12-04T15:33:33.8657152Z",
  },
  {
    id: 13,
    name: "Russian",
    displayName: "Russian (Global)",
    code: "ru",
    allowDirectLinkAccess: false,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:10:46.7693603Z",
    updatedOn: "2024-01-25T10:20:09.7632613Z",
  },
  {
    id: 14,
    name: "Hindi",
    displayName: "Hindi",
    code: "hi",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:11:07.6556140Z",
    updatedOn: "2023-12-21T22:11:07.6556140Z",
  },
  {
    id: 15,
    name: "Italian",
    displayName: "Italian",
    code: "it",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:11:24.9898000Z",
    updatedOn: "2023-12-21T22:11:24.9898000Z",
  },
  {
    id: 16,
    name: "French",
    displayName: "French",
    code: "fr",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:11:41.8190666Z",
    updatedOn: "2023-12-21T22:11:41.8190666Z",
  },
  {
    id: 17,
    name: "Tagalog",
    displayName: "Tagalog",
    code: "tl",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:11:56.4325709Z",
    updatedOn: "2023-12-21T22:11:56.4325709Z",
  },
  {
    id: 18,
    name: "Thai",
    displayName: "Thai",
    code: "th",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:12:10.0110307Z",
    updatedOn: "2023-12-21T22:12:10.0110307Z",
  },
  {
    id: 19,
    name: "Vietnamese",
    displayName: "Vietnamese",
    code: "vi",
    allowDirectLinkAccess: true,
    isActive: true,
    brandCode: "xbo",
    createdOn: "2023-12-21T22:12:25.2983457Z",
    updatedOn: "2023-12-21T22:12:25.2983457Z",
  },
];
