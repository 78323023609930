import { useLayoutEffect, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

export const useSizeVariable = (name: string, keepOnUnmount = false) => {
  const ref = useRef<HTMLDivElement>(null)
  const { height = 0 } = useResizeObserver({ ref, box: 'border-box' })

  useLayoutEffect(() => {
    if (height) document.documentElement.style.setProperty(`--${name}`, `${height}px`);

    return () => {
      if (!keepOnUnmount) document.documentElement.style.setProperty(`--${name}`, '0px');
    };
  }, [height, name, keepOnUnmount]);

  return { ref, height };
};
