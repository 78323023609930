import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Flex, Popconfirm, Space, Table, Tag, Tooltip } from "antd";
import { EditOutlined, CheckOutlined, RightOutlined } from "@ant-design/icons";

import type { InputRef, TableProps } from "antd";

import { useFormContext, Path, useFieldArray } from "react-hook-form";

import Field from "../../../components/Field/Field.component";

import { FormType, KeysFieldType } from "types/form.types";
import "./index.scss";
import { useI18n } from "hooks/useI18n.hook";
import { ColumnGroupType, ColumnType } from "antd/es/table";

export const getCssVariable = (variable: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof KeysFieldType;
  record: KeysFieldType;
  handleSave: (record: KeysFieldType) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    formState,
  } = useFormContext<FormType>();
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const translate = useI18n();
  // const form = useContext(EditableContext)!;

  const formValues: KeysFieldType[] = watch("keys");

  const [index] = useState(
    formValues.findIndex((key) => key.GUID === record?.GUID)
  );

  const name: Path<FormType> = `keys.${index}.${dataIndex}`;

  // const { invalid } = getFieldState(name);
  const { errors } = formState;

  const fieldValue = watch(name);

  const isValid = !!fieldValue;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    // form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    if (!isValid) return;
    try {
      toggleEdit();

      // const index = formValues.findIndex(key => key.id === record.id)
      // const values = await form.validateFields();

      // toggleEdit();
      // handleSave({ ...record, ...values });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Flex gap={6} align='center'>
        <Tooltip title={translate("SAVE")}>
          <Button
            onClick={toggleEdit}
            icon={<CheckOutlined />}
            size='small'
            disabled={!isValid}
          />
        </Tooltip>
        <Field
          className='editable-cell-value-wrap'
          control={control}
          name={`keys.${index}.${dataIndex}`}
          onBlur={save}
          style={{ minWidth: "auto" }}
          required
        />
      </Flex>
    ) : (
      // <Field control={control} name={`keys.${0}.Key`} onBlur={save} />
      // <Form.Item
      //   style={{ margin: 0 }}
      //   name={dataIndex}
      //   rules={[
      //     {
      //       required: true,
      //       message: `${title} is required.`,
      //     },
      //   ]}
      // >
      //   <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      // </Form.Item>
      <Flex gap={6} align='center' style={{ minHeight: 45 }}>
        <Tooltip title={translate("EDIT")}>
          <Button onClick={toggleEdit} icon={<EditOutlined />} size='small' />
        </Tooltip>
        {/* <CheckOutlined /> */}
        {/* <Field className='editable-cell-value-wrap' control={control} name={`keys.${index}.${dataIndex}`} onBlur={save} /> */}
        <div className='editable-cell-value-wrap' onClick={toggleEdit}>
          {children}
        </div>
      </Flex>
    );
  }

  return (
    <td key={name} {...restProps}>
      {childNode}
    </td>
  );
};

// const data: DataType[] = [
//   {
//     key: '1',
//     name: 'John Brown',
//     age: 32,
//     address: 'New York No. 1 Lake Park',
//     tags: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     name: 'Jim Green',
//     age: 42,
//     address: 'London No. 1 Lake Park',
//     tags: ['loser'],
//   },
//   {
//     key: '3',
//     name: 'Joe Black',
//     age: 32,
//     address: 'Sydney No. 1 Lake Park',
//     tags: ['cool', 'teacher'],
//   },
// ];

const TableComponent: React.FC<{
  data: KeysFieldType[];
  onSubmit: () => void;
}> = ({ data, onSubmit }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    formState,
    getFieldState,
  } = useFormContext<FormType>();
  const { remove } = useFieldArray({ control, name: "keys" });
  const translate = useI18n();

  const columns: ((
    | ColumnGroupType<KeysFieldType>
    | ColumnType<KeysFieldType>
  ) & { editable?: boolean; dataIndex: string })[] = [
    // const columns: TableProps<KeysFieldType>['columns'] & { editable?: boolean; dataIndex: string } = [
    {
      title: translate("KEY"),
      dataIndex: "Key",
      width: "25%",
      key: "Key",
      editable: true,
      // render: (text) => <a>{text}</a>,
    },
    {
      title: translate("TRANSLATION"),
      dataIndex: "Translate",
      width: "50%",
      key: "Translate",
      editable: true,
      // render: (text) => <a>{text}</a>,
    },
    {
      title: translate("LANGUAGE"),
      dataIndex: "Language",
      // width: '10%',
      align: "center",
      key: "Language",
      render: (text) => (
        <Flex align='center' justify='center'>
          <Tag color='blue'>{text}</Tag>
        </Flex>
      ),
    },
    {
      title: translate("CONTROLLER"),
      dataIndex: "Controller",
      // width: '10%',
      align: "center",
      key: "Controller",
      render: (text) => (
        <Flex align='center' justify='center'>
          <Tag color='blue'>{text}</Tag>
        </Flex>
      ),
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (value, _record, index) => (
        <Popconfirm
          title={translate("ARE_YOU_SURE")}
          // description="Are you sure?"
          okText={translate("YES")}
          cancelText={translate("NO")}
          okButtonProps={{ size: "middle" }}
          style={{ width: "100%" }}
          cancelButtonProps={{ size: "middle" }}
          placement='left'
          icon={null}
          onConfirm={() => remove(index)}
        >
          <Button danger type='text' size='small'>
            {translate("DELETE")}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const keys = watch("keys");

  // @ts-ignore
  const _columns: TableProps<KeysFieldType>["columns"] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: KeysFieldType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: () => null,
      }),
    };
  });

  const { invalid } = getFieldState("keys");

  const nonEmptyData = useMemo(
    () =>
      keys
        .filter((row) => !!row.Key && !!row.Translate)
        .sort((left, right) => left.Key.localeCompare(right.Key)),
    [keys]
  );

  return (
    <div className='summary'>
      <Table
        rowKey='id'
        sticky
        pagination={false}
        className='summary-table'
        columns={_columns}
        dataSource={nonEmptyData}
        components={{ body: { cell: EditableCell } }}
      />
      <Button
        onClick={onSubmit}
        disabled={invalid}
        type='primary'
        size='large'
        // iconPosition='end'
        style={{ width: 200 }}
      >
        {translate("PROCEED")}
      </Button>
    </div>
  );
};

export default TableComponent;
