import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import CsvUploaderComponent from "components/CsvUploader/CsvUploader.component";
import { useI18n } from "hooks/useI18n.hook";
import { memo } from "react";

const Controls = ({ onAdd, onUpload }: { onAdd: any; onUpload: any }) => {
  const translate = useI18n();
  return (
    <>
      <Flex gap={6}>
        <Button onClick={onAdd} icon={<PlusOutlined />} size='small'>
          {translate("ADD_ROW")}
        </Button>
        <CsvUploaderComponent
          text={translate("LOAD_CSV")}
          onChange={onUpload}
        />
      </Flex>
    </>
  );
};

export default memo(Controls);
