import { KeysFieldType } from "types/form.types";
import { v4 } from "uuid";
import convertcsvToJson from 'csvtojson';


// export interface FormKeysDto extends KeysFieldType {
//   Key: string;
//   Translate: string;
//   // Translations: { [language: string]: string };
//   Controller: string;
//   Language: string;
//   ResourceType: string;
//   View: string;
// }

// function arrayBufferToString(buffer: ArrayBuffer): string {
//   const uint8Array = new Uint8Array(buffer);
//   const charCodes: number[] = [];

//   for (let i = 0; i < uint8Array.length; i++) {
//     charCodes.push(uint8Array[i]);
//   }

//   return String.fromCharCode.apply(null, charCodes);
// }

function splitByCommaOutsideQuotes(str: string): string[] {
  const result: string[] = [];
  let startIdx = 0;
  let insideQuotes = false;

  if (!str.includes('"')) return str.split(',');

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === '"') {
      insideQuotes = !insideQuotes;
    } else if (char === ',' && !insideQuotes) {
      result.push(str.slice(startIdx, i).trim());
      startIdx = i + 1;
    }
  }

  // Add the remaining part
  result.push(str.slice(startIdx).trim());

  return result;
}

export async function csvToJson(
  csvString: string | ArrayBuffer | null
): Promise<KeysFieldType[]> {
  if (csvString === null) {
    return [];
  }

  const arrayBufferToString = (buffer: ArrayBuffer): string => {
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(buffer);
  };

  const csvText =
    typeof csvString === "string" ? csvString : arrayBufferToString(csvString);
  const lines = csvText.split("\n");

  const result: KeysFieldType[] = await convertcsvToJson().fromString(csvText).then((json) => json);

  if (result) return result.map((i) => ({ ...i, GUID: v4() }));

  const jsonData: KeysFieldType[] = [];

  for (let i = 1; i < lines.length; i++) {
    const parts = splitByCommaOutsideQuotes(lines[i]);
    const currentLine = lines[i].split(/,/);

    if (!currentLine[5]) {
      continue;
    }

    // const key = currentLine[0].replace(/"/g, "").trim();
    // const language = currentLine[2].replace(/"/g, "").trim();
    // const value = currentLine[5].replace(/"/g, "").trim();
    // const view = currentLine[1].replace(/"/g, "").trim();
    // const controller = currentLine[3].replace(/"/g, "").trim();
    // const resourceType = currentLine[4].replace(/"/g, "").trim();

    const key = parts[0].replace(/"/g, "").trim();
    const language = parts[2].replace(/"/g, "").trim();
    const value = parts[5].replace(/"/g, "").trim();
    const view = parts[1].replace(/"/g, "").trim();
    const controller = parts[3].replace(/"/g, "").trim();
    const resourceType = parts[4].replace(/"/g, "").trim();

    // const existingEntryIndex = jsonData.findIndex((entry) => entry.Key === key);

    // if (existingEntryIndex !== -1) {
    //   jsonData[existingEntryIndex].Translations[language] = value;
    // } else {
    //   jsonData.push({
    //     Key: key,
    //     Translate: value,
    //     // Translations: { [language]: value },
    //     Controller: controller,
    //     Language: language,
    //     ResourceType: resourceType,
    //     View: view,
    //   });
    // }
    jsonData.push({
      GUID: v4(),
      Key: key,
      Translate: value,
      Controller: controller,
      Language: language,
      ResourceType: resourceType,
      View: view,
      CountryCodes: "",
    });

  }

  return jsonData;
}
