const en = {
  CREATE_CSV: "Create csv",
  CREATE_CSV_DESCRIPTION: "Create csv using form",
  IMPORT_CSV: "Import csv",
  IMPORT_CSV_DESCRIPTION: "Import csv from your device",

  ADD_ROW: "Add row",
  LOAD_CSV: "Load csv",

  DELETE: "Delete",
  ARE_YOU_SURE: "Are you sure?",
  YES: "Yes",
  NO: "No",
  DOWNLOAD: "Download",
  PROCEED: "Proceed",
  CONFIRM: "Confirm",
  EDIT: "Edit",
  SAVE: "Save",
  LOGIN: "Log in",
  LOGIN_AS: "Log in as",
  LOGED_IN_AS: "Logged in as:",
  COMPLETE_IMPORT: "Complete import",
  READY_FOR_IMPORT: "Ready for import",
  RESOLVE_CONFLICTS: "Resolve conflicts to proceed",
  DUPLICATES_WILL_BE_REMOVED: "",
  // DUPLICATES_WILL_BE_REMOVED: "Select keys to keep",

  CONTROLLER: "Controller",
  KEY: "Key",
  TRANSLATION: "Translation",
  LANGUAGE: "Language",

  ENVIRONMENT: "Environment",
  BRAND: "Brand",
  TOTAL: "Total",
  IMPORTED: "Imported",
  IGNORED: "Ignored",

  STATUS_DONE: "Done!",
};

const ua = {
  CREATE_CSV: "Створити csv",
  CREATE_CSV_DESCRIPTION: "Створити csv за допомогою форми",
  IMPORT_CSV: "Імпортувати csv",
  IMPORT_CSV_DESCRIPTION: "Імпортувати csv файл",

  ADD_ROW: "Додати рядок",
  LOAD_CSV: "Завантажити csv",

  DELETE: "Видалити",
  ARE_YOU_SURE: "Ви впевнені?",
  YES: "Так",
  NO: "Ні",
  DOWNLOAD: "Скачати",
  PROCEED: "Продовжити",
  CONFIRM: "Підтвердити",
  EDIT: "Редагувати",
  SAVE: "Зберегти",
  LOGIN: "Увійти",
  LOGIN_AS: "Увійти як",
  LOGED_IN_AS: "Авторизований як:",
  COMPLETE_IMPORT: "Завершити імпорт",
  READY_FOR_IMPORT: "Готово до імпорту",
  RESOLVE_CONFLICTS: "Виправте конфлікти щоб продовжити",
  DUPLICATES_WILL_BE_REMOVED: "",
  // DUPLICATES_WILL_BE_REMOVED: "Оберіть ключі які необхідно залишити",

  CONTROLLER: "Контроллер",
  KEY: "Ключ",
  TRANSLATION: "Переклад",
  LANGUAGE: "Мова",

  ENVIRONMENT: "Середовище",
  BRAND: "Бренд",
  TOTAL: "Загальна кількість",
  IMPORTED: "Імпортовані",
  IGNORED: "Проігноровані",

  STATUS_DONE: "Готово!",
};

export type LocalizationsCollection = keyof typeof en;

// export type LocalizationsCollection = Record<FormLanguage, LocalizationsLanguageCollection>;

// const localizations: LocalizationsCollection = {
//   en: {
//     CREATE_CSV: 'Create csv'
//   },
//   ua: {
//     CREATE_CSV: 'Створити csv'
//   }
// }

const result = { en, ua };

export default result;
