import { KeysFieldType } from "types/form.types";

export const findDuplicates = (items: KeysFieldType[]) => {
  const keyMap = new Map<string, KeysFieldType[]>();

  // Group items by Key
  items.forEach((item) => {
    const _id = `${item.Key}_${item.Language}`;

    if (!keyMap.has(_id)) {
      keyMap.set(_id, []);
    }
    keyMap.get(_id)!.push(item);
  });

  // Filter groups that have more than one item
  const duplicates = Array.from(keyMap.values()).filter(
    (group) => group.length > 1
  );

  return duplicates;
};
