import { theme, ThemeConfig } from "antd";

const _theme: ThemeConfig = {
  // token: {
  //   colorPrimary: "#000000",
  //   colorInfo: "#000000",
  // },
  // components: {
  //   Input: {
  //     activeShadow: "0 0 0 0",
  //     controlHeight: 42,
  //   },
  //   Select: {
  //     controlOutline: "rgb(0, 0, 0)",
  //     controlOutlineWidth: 0,
  //     optionSelectedColor: "white",
  //     controlHeight: 42,
  //   },
  //   Tag: {
  //     colorInfo: "white",
  //     colorInfoBg: "#000000",
  //   },
  //   Card: {
  //     colorBgContainer: "#0000002e",
  //   },
  // },
  cssVar: true,
  components: {
    Input: {
      controlHeight: 42,
    },
    Select: {
      controlHeight: 42,
    },
    Segmented: {
      // trackPadding: 2,
      // itemSelectedBg: "#DDDDDD",
      itemSelectedColor: "#1677ff",
    },
  },
  // algorithm: theme.compactAlgorithm,
  // token: {
  //   colorPrimary: "#811e96",
  //   colorInfo: "#811e96",
  // },
  // token: {},
  // components: {
  //   Button: {},
  //   Form: {
  //     itemMarginBottom: 0,
  //     verticalLabelPadding: 4,
  //     fontWeightStrong: 12,
  //   },
  //   Card: {
  //     colorPrimary: "#282c34",
  //   },
  //   Modal: {
  //     titleFontSize: 18,
  //     titleColor: "#1677ff",
  //   },
  //   Radio: {},
  //   Input: {
  //     colorBorder: "#98a9c4",
  //     controlHeight: 42,
  //   },
  //   Select: {
  //     colorBorder: "#98a9c4",
  //     controlHeight: 42,
  //   },
  //   Upload: {
  //     colorTextHeading: "#FFFFFF",
  //     colorText: "#FFFFFF",
  //   },
  //   Table: {
  //     padding: 8,
  //   },
  //   Segmented: {
  //     itemSelectedColor: "var(--btn-primary-bg)",
  //     itemHoverColor: "var(--btn-primary-bg)",
  //   },
  //   Menu: {},
  // },
};

export default _theme;
