import axios, { AxiosError, AxiosResponse } from "axios";
import { FormAuth, FormType } from "types/form.types";
import { stringToFile } from "utils/stringToFile";

import { endpoints } from "./api.constants";

export type AuthResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
};

export type ImportResponse = {
  importedCount: number;
  ignoredCount: number;
  total: number;
};

export type AuthResponseFail = AxiosError<{
  error?: string;
  error_description?: string;
}>;

export type ImportResponseFail = AxiosError<{
  message?: string;
}>;

const api = {
  authenticate: ({ email, password, env }: FormAuth): Promise<AuthResponse> => {
    const options = {
      method: "POST",
      timeout: 10000,
      url: `${endpoints[env].identity}/connect/token`,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: {
        grant_type: localStorage.getItem("identity.grant_type") || "password",
        username: email,
        password: password,
        client_id:
          localStorage.getItem("identity.client_id") || "backoffice.ui",
        client_secret:
          localStorage.getItem("identity.client_secret") || "secret",
      },
    };

    return axios(options).then((response) => response.data);
  },

  getBrands: (formValues: FormAuth): Promise<FormType["brand"]> => {
    const options = {
      method: "GET",
      timeout: 10000,
      url: `${endpoints[formValues.env].socket}/dictionaries/brands`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${formValues.token?.access_token}`,
      },
    };

    return axios(options).then((response: AxiosResponse<FormType["brand"][]>) =>
      response.data.find((x) => x?.isDefault)
    );
  },

  postImport: (formValues: FormAuth, csv: string): Promise<ImportResponse> => {
    const options = {
      method: "POST",
      url: `${endpoints[formValues.env].cms}/v2/brands/${
        formValues.brand?.id
      }/resources/import`,
      timeout: 10000000,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${formValues.token?.access_token}`,
      },
      // data: undefined,
      data: stringToFile(csv),
    };

    return axios(options).then(
      (response: AxiosResponse<ImportResponse>) => response.data
    );
  },
};

export default api;
