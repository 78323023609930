import React, { useCallback, useEffect, useMemo, useState } from "react";

import type { FormProps, RadioChangeEvent } from "antd";

import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Dropdown,
  Flex,
  Modal,
  notification,
  Radio,
  Space,
  Typography,
} from "antd";
import { RightOutlined, UserOutlined } from "@ant-design/icons";

import "./index.css";
import { useLocalStorage } from "usehooks-ts";
import { Path, useFormContext } from "react-hook-form";
import { FormAuth, FormAuthEnv, FormType } from "types/form.types";
import api, { AuthResponseFail } from "api";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes.constants";
import { useI18n } from "hooks/useI18n.hook";

import { composeNotificationError } from "../ErrorMessage/ErrorMessage.component";
import Field from "../Field/Field.component";

type AuthFormProps = {
  path: FormAuthEnv;
};

const AuthForm: React.FC<AuthFormProps> = ({ path }) => {
  const navigate = useNavigate();
  const {
    control,
    setValue,
    watch,
    register,
    getFieldState,
    formState: { errors, isValid },
  } = useFormContext<FormType>();
  const translate = useI18n();
  const [notificationApi, contextHolder] = notification.useNotification({
    top: 16,
    placement: "bottom",
  });

  const [storageCredentials, updateStorageCredentials] = useLocalStorage<
    FormAuth[]
  >("xbo-migrator-credentials", []);

  const [isProcessingAuth, toggleIsProcessingAuth] = useState(false);
  const [status, updateStatus] = useState<{ code?: number; message?: string }>({
    code: undefined,
    message: undefined,
  });

  // useEffect(() => {
  //   register(`auth.${path}.env`);
  // }, [path, register]);

  const formValues = watch(`auth.${path}`);

  const isInvalid = !formValues.email || !formValues.password;

  // const { invalid } = getFieldState(`auth.${path}`);
  const credentials = useMemo(
    () => storageCredentials.filter((x) => x.env === formValues.env),
    [formValues.env, storageCredentials]
  );

  // const onChangeEnv = useCallback(
  //   (event: RadioChangeEvent) => {
  //     setValue("auth.env", event.target.value);
  //   },
  //   [setValue]
  // );

  const handleSubmit = useCallback(
    async (user: FormAuth = formValues) => {
      toggleIsProcessingAuth(true);
      const uniqueCredentials = storageCredentials.filter(
        (credentials) => credentials.env !== formValues.env
      );
      updateStorageCredentials([
        ...uniqueCredentials,
        { ...user, password: btoa(user.password) },
      ]);

      const token = await api
        .authenticate(user)
        .catch((error: AuthResponseFail) => {
          updateStatus({
            message:
              error.response?.data.error_description ||
              error.response?.data.error,
            code: error.response?.status,
          });
          notificationApi.error({
            ...composeNotificationError({
              code: error.response?.status,
              message:
                error.response?.data.error_description ??
                error.response?.data.error,
            }),
            // description: <ErrorMessageComponent code={error.response?.status} message={error.response?.data.message} />,
            duration: 3,
            // icon: <ExclamationCircleTwoTone twoToneColor='red' />
          });
        });

      if (!token) {
        toggleIsProcessingAuth(false);
        return;
      }

      setValue(`auth.${path}.token`, token);

      const brand = await api.getBrands({
        ...formValues,
        token,
      });

      setValue(`auth.${path}.brand`, brand);
      toggleIsProcessingAuth(false);

      // navigate(routes.Brand());
    },
    [
      formValues,
      notificationApi,
      path,
      setValue,
      storageCredentials,
      updateStorageCredentials,
    ]
  );

  // const onSubmit = useCallback(
  //   async (user: FormType["auth"] = formValues) => {
  //     toggleIsProcessingAuth(true);
  //     const uniqueCredentials = storageCredentials.filter(
  //       (credentials) => credentials.env !== formValues.env
  //     );
  //     updateStorageCredentials([...uniqueCredentials, user]);

  //     const token = await api.authenticate(user).catch((error: AuthResponseFail) => updateStatus({ message: error.response?.data.error_description || error.response?.data.error, code: error.response?.status }));

  //     if (!token) {
  //       toggleIsProcessingAuth(false);
  //       return;
  //     }

  //     const brand = await api.getBrands(token);

  //     setValue("token", token);
  //     setValue("brand", brand);

  //     toggleIsProcessingAuth(false);

  //     navigate(routes.Brand());
  //   },
  //   [formValues, navigate, setValue, status, storageCredentials, updateStorageCredentials]
  // );

  const onChooseCredentials = useCallback(
    (user: FormAuth) => {
      const _user: FormAuth = { ...user, password: atob(user.password) };
      setValue(`auth.${path}`, _user);
      handleSubmit(_user);
    },
    [handleSubmit, path, setValue]
  );

  const isAuthenticated = useMemo(() => !!formValues.token, [formValues.token]);

  const renderAuthState = useCallback(
    () =>
      isAuthenticated && (
        <Space>
          <Avatar shape='square' size='large' icon={<UserOutlined />} />
          <Flex vertical>
            <p className='text-light-grey'>{translate("LOGED_IN_AS")} </p>
            <span>{formValues.email}</span>
          </Flex>
          {/* <Descriptions
              // colon
              layout='vertical'
              size="small"
              // column={1}
              items={[
                {
                  key: "1",
                  label: "Loged in as",
                  children: formValues.email
                },
              ]}
            /> */}
          {/* <span>{formValues.email}</span> */}
        </Space>
      ),
    [formValues.email, isAuthenticated, translate]
  );

  const renderFormState = useCallback(
    () =>
      !isAuthenticated && (
        <>
          <Flex vertical gap={8}>
            <Field
              type='email'
              name={`auth.${path}.email`}
              control={control}
              placeholder='Email'
              disabled={isAuthenticated}
              // autoComplete='off'
            />
            <Field
              type='password'
              name={`auth.${path}.password`}
              control={control}
              placeholder='Password'
              disabled={isAuthenticated}
              // autoComplete='off'
            />
          </Flex>

          <Flex vertical gap={6}>
            <Button
              disabled={isInvalid || isAuthenticated}
              loading={isProcessingAuth}
              type={isAuthenticated ? "text" : "primary"}
              style={{
                flex: 1,
                color: isAuthenticated ? "var(--green)" : "var(--white)",
              }}
              size='large'
              iconPosition='end'
              onClick={() => handleSubmit()}
            >
              {isAuthenticated ? "Successfully logged in" : translate("LOGIN")}
            </Button>
            {credentials.map((user) => (
              <Button
                disabled={isAuthenticated}
                onClick={() => onChooseCredentials(user)}
                key={`${user.env}:${user.email}`}
                size='large'
              >
                <Typography.Text ellipsis>
                  {translate("LOGIN_AS")} {user.email}
                </Typography.Text>
              </Button>
            ))}
          </Flex>
        </>
      ),
    [
      control,
      credentials,
      handleSubmit,
      isAuthenticated,
      isInvalid,
      isProcessingAuth,
      onChooseCredentials,
      path,
      translate,
    ]
  );

  return (
    <form className='auth-form'>
      {contextHolder}
      <Flex vertical gap={8} justify='start' style={{ minHeight: 190 }}>
        {renderAuthState()}
        {renderFormState()}
        {/* <Flex gap={4}>
          {credentials.map((user) => (
            <Card
              // onClick={() => onChooseCredentials(user)}
              key={`${user.env}:${user.email}`}
              hoverable
            >
              <Card.Meta description='Continue as' />
              {user.email}
            </Card>
          ))}
        </Flex> */}
        {/* <Flex gap={4} align='stretch' justify='stretch'>
          {credentials.map((user) => (
            <Card
              // onClick={() => onChooseCredentials(user)}
              key={`${user.env}:${user.email}`}
              hoverable
            >
              <Card.Meta description='Continue as' />
              {user.email}
            </Card>
          ))}
        </Flex> */}

        {/* <Flex vertical gap={4} align='stretch' justify='stretch'>
          {credentials.map((user) => (
            <Card
              onClick={() => onChooseCredentials(user)}
              key={`${user.env}:${user.email}`}
              hoverable
            >
              <Card.Meta description='Continue as' />
              {user.email}
            </Card>
          ))}
        </Flex> */}
      </Flex>
    </form>
    // <Modal
    //   title='Authenticate'
    //   open
    //   centered
    //   closable={false}
    //   footer={[
    //     <Flex vertical gap={8}>

    //     {status.message && (
    //       <Card className="server-error">

    //       {/* <div className="server-error"> */}
    //         <p className="server-error-title">Oops!</p>
    //         <p className="server-error-message">{status.message}</p>
    //       {/* </div> */}
    //       </Card>
    //     )}
    //     <Button
    //       disabled={invalid}
    //       loading={isProcessingAuth}
    //       block
    //       type='primary'
    //       size='large'
    //       iconPosition='end'
    //       icon={<RightOutlined />}
    //       onClick={() => onSubmit()}
    //     >
    //       Proceed
    //     </Button>
    //     </Flex>
    //   ]}
    //   confirmLoading
    // >
    //   <Flex vertical gap={16} justify='space-around'>

    //     <Flex vertical align='start'>
    //       <Radio.Group defaultValue='dev' onChange={onChangeEnv}>
    //         <Radio.Button value='dev'>dev</Radio.Button>
    //         <Radio.Button value='qa'>qa</Radio.Button>
    //         <Radio.Button value='prod'>prod</Radio.Button>
    //       </Radio.Group>
    //     </Flex>

    //     <Flex vertical gap={8}>
    //       <Field
    //         type='email'
    //         name='auth.email'
    //         control={control}
    //         placeholder='Email'
    //         autoComplete='off'
    //       />
    //       <Field
    //         type='password'
    //         name='auth.password'
    //         control={control}
    //         placeholder='Password'
    //         autoComplete='on'
    //       />
    //     </Flex>

    //     <Flex vertical gap={4} align='stretch' justify='stretch'>
    //       {credentials.map((user) => (
    //         <Card
    //           onClick={() => onChooseCredentials(user)}
    //           key={`${user.env}:${user.email}`}
    //           hoverable
    //         >
    //           <Card.Meta description='Continue as' />
    //           {user.email}
    //         </Card>
    //       ))}
    //     </Flex>
    //   </Flex>
    // </Modal>
  );
};

export default AuthForm;
