import { Card, Flex } from "antd";
import { memo, useEffect } from "react";
import routes from "routes/routes.constants";
import { Link } from "react-router-dom";
import CsvUploaderComponent from "components/CsvUploader/CsvUploader.component";
import { useI18n } from "hooks/useI18n.hook";
import { useFormContext } from "react-hook-form";
import { FormType } from "types/form.types";

import { ReactComponent as IconCreate } from "../../icons/create.svg";
import { ReactComponent as IconImport } from "../../icons/import.svg";

const HomeContainer = () => {
  const translate = useI18n();
  const { reset } = useFormContext<FormType>();

  useEffect(
    function resetForm() {
      reset();
    },
    [reset]
  );

  return (
    <Flex className='home' align='center' justify='center' gap={16}>
      <Link to={routes.Create()}>
        <Card
          style={{ width: 300 }}
          hoverable
          bordered={false}
          cover={<IconCreate style={{ width: "100%", aspectRatio: 1 / 1 }} />}
        >
          <Card.Meta
            title={translate("CREATE_CSV")}
            description={translate("CREATE_CSV_DESCRIPTION")}
          />
        </Card>
      </Link>
      <CsvUploaderComponent>
        <Card
          style={{ width: 300 }}
          hoverable
          bordered={false}
          cover={<IconImport style={{ width: "100%", aspectRatio: 1 / 1 }} />}
        >
          <Card.Meta
            title={translate("IMPORT_CSV")}
            description={translate("IMPORT_CSV_DESCRIPTION")}
          />
        </Card>
      </CsvUploaderComponent>
    </Flex>
  );
};

export default memo(HomeContainer);
