import { useLocalStorage } from "usehooks-ts";
import { useParams } from "react-router-dom";
import { ConfigProvider, Switch } from "antd";
import React, { memo, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import MenuComponent from "components/Menu/Menu.component";
import { FormLanguage, FormType } from "types/form.types";
import { useSizeVariable } from "hooks/useSizeVariable.hook";
import LanguageSwitcherComponent from "components/LanguageSwitcher/LanguageSwitcher.component";
import localizations from "constants/localizations";

import "./index.scss";
import CreateComponent from "views/create/Create.component";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ProjectSwitcherComponent from "components/ProjectSwitcher/ProjectSwitcher.component";
import FieldSelect from "components/Field/FieldSelect.component";

import _theme from "./theme";

const Main: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { ref } = useSizeVariable("container-height");
  const [language, updateLanguage] = useLocalStorage<FormLanguage>(
    "xbo-migrator-language",
    "en"
  );
  const [animateRef] = useAutoAnimate();

  useEffect(() => {
    const doc = document.documentElement;
    doc.style.setProperty(
      "--app-height",
      `${document.documentElement.clientHeight}px`
    );
  }, []);

  const defaultValues: FormType = useMemo(
    () => ({
      language: language,
      localizations: localizations[language],
      keys: [
        // { Key: "", Translate: "", Controller: undefined, id: 0, View: 'Labels', Language: 'English', ResourceType: '3', CountryCodes: '' }
      ],
      auth: {
        development: { env: "development", email: "", password: "" },
        qa: { env: "qa", email: "", password: "" },
        production: { env: "production", email: "", password: "" },
      },
      token: undefined,
      project: "xbo",
    }),
    [language]
  );

  const methods = useForm<FormType>({
    defaultValues,
    mode: "onChange",
  });

  const project = methods.watch("project");

  return (
    <ConfigProvider theme={_theme}>
      <FormProvider {...methods}>
        <header className='header'>
          <MenuComponent />
          <LanguageSwitcherComponent />
          {/* <ProjectSwitcherComponent /> */}
          {/* {project === "b2b" && (
            <FieldSelect
              name={"auth.development.brand.name"}
              options={[
                { value: "equiity", label: "equiity" },
                { value: "modmount", label: "modmount" },
              ]}
            />
          )} */}
        </header>

        <main ref={ref} className='container'>
          {children}
          {/* <ConfigProvider theme={_theme}>{children}</ConfigProvider> */}
        </main>
      </FormProvider>
    </ConfigProvider>
  );
};

export default memo(Main);
