import { FormType, KeysFieldType } from "types/form.types";

const _jsonToCsv = (jsonArray: FormType["keys"] = []): string => {
  const fields: Array<keyof KeysFieldType> = [
    "Key",
    "View",
    "Language",
    "Controller",
    "ResourceType",
    "Translate",
    "CountryCodes",
  ];
  const replacer = function (key: string, value: any): any {
    return value === null ? "" : value;
  };
  let csv = jsonArray.map((row) =>
    fields
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  csv.unshift(fields.join(",")); // add header column
  const result = csv.join("\r\n");
  return result;
};

export const jsonToCsv = (json: FormType["keys"]): string => {
  const apiKeysArray: FormType["keys"] = [];

  json.forEach((item) => {
    // Object.entries(item.Translations).forEach(([language, translate]) => {
    //   if (translate.trim() !== '') {
    //     const apiKeysItem: FormType['keys'] = {
    //       Key: item.Key,
    //       Controller: item.Controller,
    //       Language: language,
    //       ResourceType: item.ResourceType,
    //       View: item.View,
    //       Translate: translate,
    //       // Add other properties as needed (e.g., CountryCodes)
    //     };

    //     apiKeysArray.push(apiKeysItem);
    //   }
    // });
    apiKeysArray.push({
      Key: item.Key,
      Controller: item.Controller,
      Language: item.Language,
      ResourceType: item.ResourceType ?? '3',
      View: item.View ?? 'Labels',
      Translate: item.Translate,
      CountryCodes: "",
      // Add other properties as needed (e.g., CountryCodes)
    });
  });

  return _jsonToCsv(apiKeysArray);
};
