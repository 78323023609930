import { Flex, Input, InputProps, Select, SelectProps } from "antd";
import classNames from "classnames";
import {
  Control,
  Controller,
  ControllerRenderProps,
  Path,
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { FormType } from "types/form.types";
import "./index.scss";
import { memo, useCallback } from "react";

type FieldType<T extends FormType> = {
  control?: Control<T>;
  name: Path<T>;
  // type?: "text" | "number" | "password" | "email" | "textarea";
  options?: never;
  rules?: UseControllerProps["rules"];
} & InputProps;

type FieldSelectType<T extends FormType> = {
  control?: Control<T>;
  name: Path<T>;
  // type: "select";
  options: SelectProps["options"];
  rules?: UseControllerProps["rules"];
} & InputProps;

const FieldSelect = <T extends FormType>({
  // control,
  name,
  // type = "text",
  options = [],
  rules = {},
  ...rest
}: FieldType<T> | FieldSelectType<T>) => {
  const { control } = useFormContext<FormType>();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true, ...rules }}
      render={({ field }) => (
        <Select
          style={{ minWidth: 150, maxWidth: 150 }}
          options={options}
          placeholder={rest.placeholder}
          {...field}
        />
      )}
    />
  );
};

export default FieldSelect;
