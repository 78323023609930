import React from "react";
import ReactDOM from "react-dom/client";
import RootComponent from "old/entry/Root.component";

import reportWebVitals from "./reportWebVitals";
import Router from "./routes/Router.component";
import "./index.css";

import "./fonts/Pier/PierSans-Regular.otf";
import "./fonts/Pier/PierSans-Bold.otf";
import "./styles/reset.scss";
import "./styles/variables.scss";
import "./styles/errors.scss";
import "./styles/button.scss";
import "./styles/utils.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

// const old = ReactDOM.createRoot(document.getElementById("old") as HTMLElement);
// old.render(
//   <React.StrictMode>
//     <RootComponent />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
