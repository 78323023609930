import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormType, KeysFieldType } from "types/form.types";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes.constants";
import { findDuplicates } from "utils/findDuplicates";
import {
  Button,
  Card,
  Flex,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
} from "antd";
import { useI18n } from "hooks/useI18n.hook";

import TableComponent from "./Table/Table.component";

const ReviewContainer = () => {
  const { watch, setValue } = useFormContext<FormType>();
  const navigate = useNavigate();
  const translate = useI18n();

  const keys = watch("keys");

  const duplicates = findDuplicates(keys);

  const [awaitingConflictResolve, toggleAwaitingConflictResolve] =
    useState(false);

  const [isDuplicatesResolved, toggleIsDuplicatesResolved] = useState(
    !duplicates.length
  );

  const [resolvedKeys, updateResolvedKeys] = useState<
    Record<string, KeysFieldType>
  >({});

  const isResolvedAllConflicts = useMemo(
    () => duplicates.length === Object.keys(resolvedKeys).length,
    [duplicates.length, resolvedKeys]
  );

  const onResolveConflict = useCallback(
    (event: RadioChangeEvent) => {
      const item: KeysFieldType = event.target.value;
      updateResolvedKeys({
        ...resolvedKeys,
        [item.Key]: item,
      });
    },
    [resolvedKeys]
  );

  const onResolveConflicts = useCallback(() => {
    const data = keys.filter(
      (x) =>
        !resolvedKeys[x.Key] ||
        (resolvedKeys[x.Key] && resolvedKeys[x.Key].GUID === x.GUID)
    );

    setValue("keys", data);
    toggleIsDuplicatesResolved(true);
  }, [keys, resolvedKeys, setValue]);

  const onSubmitSuccess = useCallback(
    () => navigate(routes.Summary()),
    [navigate]
  );

  const onSubmit = useCallback(() => {
    if (duplicates.length) {
      updateResolvedKeys({});
      toggleAwaitingConflictResolve(false);
      toggleIsDuplicatesResolved(false);
      return;
    }

    onSubmitSuccess();
  }, [duplicates.length, onSubmitSuccess]);

  useEffect(() => {
    if (!keys.length) navigate(routes.Root());
  }, [keys.length, navigate]);

  return (
    <>
      <TableComponent data={[]} onSubmit={onSubmit} />
      <Modal
        closable={true}
        title={`${translate("RESOLVE_CONFLICTS")}. ${translate(
          "DUPLICATES_WILL_BE_REMOVED"
        )}`}
        open={!awaitingConflictResolve && !isDuplicatesResolved}
        onOk={onResolveConflicts}
        onCancel={() => toggleAwaitingConflictResolve(true)}
        style={{ maxHeight: "50vh" }}
        footer={[
          <Button
            key='confirm'
            type='primary'
            size='large'
            disabled={!isResolvedAllConflicts}
            onClick={onResolveConflicts}
          >
            {translate("CONFIRM")}
          </Button>,
        ]}
      >
        <Flex
          vertical
          gap={32}
          style={{ maxHeight: "70vh", overflow: "scroll" }}
        >
          {/* <p>{translate("DUPLICATES_WILL_BE_REMOVED")}</p> */}
          <Flex vertical gap={8}>
            {duplicates.map((pair, index) => (
              <Card key={index} title={`${pair[0].Key} (${pair[0].Language})`}>
                <Radio.Group onChange={onResolveConflict}>
                  <Space direction='vertical'>
                    {pair.map((data, index) => (
                      <Radio key={index} value={data}>
                        {data.Translate}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Card>
            ))}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default memo(ReviewContainer);
