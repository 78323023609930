import { Button, GetProps, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { csvToJson } from "utils/csvToJson";
import { memo, PropsWithChildren, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes.constants";
import { KeysFieldType } from "types/form.types";

interface Props extends PropsWithChildren<any> {
  onChange?: (json: KeysFieldType[]) => void;
  text?: string;
}

const CsvUploader: React.FC<
  Props & Omit<GetProps<typeof Upload>, "onChange">
> = ({ children, onChange, className, text = "" }) => {
  const { setValue, getValues } = useFormContext();
  const navigate = useNavigate();

  const keys = getValues("keys");

  const onBeforeUpload = useCallback(
    (file: RcFile) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = async () => {
        const result = await csvToJson(reader.result);

        if (onChange) return onChange(result);

        setValue("keys", [...keys, ...result], { shouldDirty: true });
        navigate(routes.Review());
      };
      return Upload.LIST_IGNORE;
    },
    [keys, navigate, onChange, setValue]
  );

  return (
    <Upload
      multiple
      name='xbo-resources'
      accept='.csv'
      beforeUpload={onBeforeUpload}
      className={className}
    >
      {children ?? (
        <Button icon={<UploadOutlined />} size='small'>
          {text}
        </Button>
      )}
    </Upload>
  );
};

export default memo(CsvUploader);
