import { Button, GetProps, Tooltip, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { csvToJson } from "utils/csvToJson";
import { memo, useCallback, useMemo } from "react";
import csvDownload from "json-to-csv-export";
import { useFormContext } from "react-hook-form";
import { FormType } from "types/form.types";
import { useI18n } from "hooks/useI18n.hook";

type Props = GetProps<typeof Button>;

const CsvDownloader: React.FC<Props> = (props) => {
  const translate = useI18n();

  const { register, control, handleSubmit, watch, setValue, getValues } =
    useFormContext<FormType>();

  const headers = useMemo(
    () => [
      "Key",
      "View",
      "Language",
      "Controller",
      "ResourceType",
      "Translate",
      "CountryCodes",
    ],
    []
  );

  const onClick = useCallback(() => {
    const data = getValues("keys").map((x) => ({
      Key: x.Key,
      View: x.View,
      Language: x.Language,
      Controller: x.Controller,
      ResourceType: x.ResourceType,
      Translate: x.Translate,
      CountryCodes: x.CountryCodes,
    }));

    csvDownload({
      data,
      delimiter: ",",
      filename: `xbo-resources-${new Date().toLocaleDateString()}`,
      headers: [
        "Key",
        "View",
        "Language",
        "Controller",
        "ResourceType",
        "Translate",
        "CountryCodes",
      ],
    });
  }, [getValues]);

  // const onBeforeUpload = useCallback((file: RcFile) => {
  //     const reader = new FileReader();
  //     reader.readAsArrayBuffer(file);
  //     reader.onloadend = () => {
  //       const result = csvToJson(reader.result);

  //       onChange(result);
  //     };
  //   return Upload.LIST_IGNORE;
  // }, [onChange]);

  return (
    <Button size='large' onClick={onClick} style={{ width: 200 }} {...props}>
      {translate("DOWNLOAD")}
    </Button>
  );
};

export default memo(CsvDownloader);
