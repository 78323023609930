import { useFormContext } from "react-hook-form";
import { FormType } from "types/form.types";
import localizations, {
  LocalizationsCollection,
} from "constants/localizations";
import { useCallback } from "react";

export const useI18n = () => {
  const { getValues } = useFormContext<FormType>();

  const language = getValues("language");

  const translate = useCallback(
    (key: LocalizationsCollection): string => localizations[language][key],
    [language]
  );

  return translate;
};
