import { Segmented } from "antd";
import { memo, useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FormLanguage, FormType } from "types/form.types";
import { useLocalStorage } from "usehooks-ts";

const LanguageSwitcher = () => {
  const { watch, setValue } = useFormContext<FormType>();
  const [, updateLanguage] = useLocalStorage<FormLanguage>(
    "xbo-migrator-language",
    "en"
  );

  const language = watch("language");

  const languages: FormLanguage[] = useMemo(() => ["en", "ua"], []);

  const onChange = useCallback(
    (value: FormLanguage) => {
      updateLanguage(value);
      setValue("language", value);
    },
    [setValue, updateLanguage]
  );

  return (
    <Segmented
      size='large'
      options={languages}
      value={language}
      onChange={onChange}
    />
  );
};

export default memo(LanguageSwitcher);
