import { memo } from "react";
import { Button } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import routes from "routes/routes.constants";
import "./index.scss";

const MenuComponent = () => {
  return (
    <Link to={routes.Root()}>
      <Button type='primary' size='large' icon={<HomeOutlined />} />
    </Link>
  );
};

export default memo(MenuComponent);
