import { FormAuthEnv } from "types/form.types";

type EnvEndpoints = {
  identity: string;
  socket: string;
  cms: string;
};

export const endpoints: Record<FormAuthEnv, EnvEndpoints> = {
  development: {
    identity: "https://crm.xbo-dev.space-app.io/api/identity",
    socket: "https://crm.xbo-dev.space-app.io/socket",
    cms: "https://crm.xbo-dev.space-app.io/api/cms/api",
  },
  qa: {
    identity: "https://crm.xbo-qa.space-app.io/api/identity",
    socket: "https://crm.xbo-qa.space-app.io/socket",
    cms: "https://crm.xbo-qa.space-app.io/api/cms/api",
  },
  production: {
    identity: "https://crm.xbo.com/api/identity",
    socket: "https://crm.xbo.com/socket",
    cms: "https://crm.xbo.com/api/cms/api",
  },
};
